import React from 'react';

import PropTypes from 'prop-types';
import CarouselMod from 'react-multi-carousel';
import styled from 'styled-components';

import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * An Carousel default component.
 *
 * @param {Object} props    The component props.
 * @param {Array}  children An array of children.
 *
 * @returns {JSX} The Carousel.
 */
const Carousel = ({children}) => {
    const responsive = {
        desktop: {
            breakpoint: {
                max: Infinity,
                min: -Infinity
            },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <CarouselContainer>
            <CarouselMod
                arrows={false}
                // eslint-disable-next-line react/jsx-boolean-value
                autoPlay={true}
                // eslint-disable-next-line react/jsx-boolean-value
                infinite={true}
                // eslint-disable-next-line react/jsx-boolean-value
                keyBoardControl={false}
                // eslint-disable-next-line react/jsx-boolean-value
                renderDotsOutside={true}
                responsive={responsive}
                // eslint-disable-next-line react/jsx-boolean-value
                showDots={true}
                // eslint-disable-next-line react/jsx-boolean-value
                ssr={true}
            >
                {children}
            </CarouselMod>
        </CarouselContainer>
    );
};

Carousel.propTypes = {children: PropTypes.node.isRequired};

export default Carousel;

const CarouselContainer = styled.div`
    margin-bottom: ${clampCss(42, 'm')};
    position: relative;

    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform, transition;
    }

    .react-multi-carousel-dot-list {
        position: absolute;
        bottom: ${clampCss(-42, 'm', true)};
        display: flex;
        left: 0;
        right: 0;
        justify-content: center;
        margin: auto;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }

    .react-multi-carousel-dot button {
        background: #C8C8D0;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 8px;
        margin: 0 8px;
        outline: 0;
        padding: 0;
        transition: background 0.3s 0s ease-in-out;
        width: 8px;
    }

    .react-multi-carousel-dot button:hover:active {
        background: #2E3542;
    }

    .react-multi-carousel-dot--active button {
        background: #2E3542;
    }

    .react-multi-carousel-item {
        backface-visibility: hidden;
        transform-style: preserve-3d;
    }

    img {
        user-drag: none !important;
    }

    @media all and (-ms-high-contrast:none), (-ms-high-contrast:active) {
        .react-multi-carousel-item {
            flex-shrink: 0 !important;
        }

        .react-multi-carousel-track {
            overflow: visible !important;
        }
    }

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(42, 't')};

        .react-multi-carousel-dot-list {
            bottom: ${clampCss(-42, 't', true)};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(50px, ${pxToVw(50, 'd')});

        .react-multi-carousel-dot-list {
            bottom: max(${pxToVw(-50, 'd')}, -50px);
        }
    }
`;