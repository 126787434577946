import React, {Component} from 'react';

import {Parser} from 'html-to-react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Carousel from 'Frontend/components/Carousel';
import {ModuleMargin} from 'Frontend/components/ModuleMargins';

import {parseToUri} from 'Admin/utils/formParsers';
import {Arrow} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';
import {isValidNode, processingInstructions} from 'Frontend/utils/parserInstructions';

/**
 * ShortCopyImage
 *
 * @property {*} prop Prop comment.
 *
 * @class ShortCopyImage
 * @extends {Component}
 */
class ShortCopyImage extends Component {
    static propTypes = {
        copy: PropTypes.string.isRequired,
        imagePosition: PropTypes.string.isRequired,
        images: PropTypes.array.isRequired,
        className: PropTypes.string,
        externalLink: PropTypes.string,
        headline: PropTypes.string,
        internalLink: PropTypes.object,
        internalSuffix: PropTypes.string,
        isExternal: PropTypes.bool,
        isInGroup: PropTypes.bool,
        linkName: PropTypes.string,
        name: PropTypes.string
    }

    static defaultProps = {
        className: '',
        externalLink: null,
        headline: null,
        internalLink: null,
        internalSuffix: null,
        isExternal: false,
        isInGroup: false,
        linkName: null,
        name: null
    }

    htmlParser = new Parser();

    /**
     * Renders the image part of the LongCopy.
     *
     * @returns {JSX} The Image jsx.
     * @memberof ShortCopyImage
     */
    renderImages() {
        const {images} = this.props;

        if (images.length === 0) {
            return null;
        }

        if (images.length > 1) {
            return (
                <Carousel>
                    {images.map(img => {
                        const {alt, dimensions: {height, width}, publicPath, title} = img;

                        return (
                            <Image
                                key={publicPath.src}
                                alt={alt}
                                height={height}
                                layout="responsive"
                                src={publicPath.src}
                                title={title}
                                width={width}
                            />
                        );
                    })}
                </Carousel>
            );
        }

        const {alt, dimensions: {height, width}, publicPath, title} = images[0];

        return (
            <Image
                alt={alt}
                height={height}
                layout="responsive"
                src={publicPath.src}
                title={title}
                width={width}
            />
        );
    }

    /**
     * Renders the link.
     *
     * @returns {JSX} The react component.
     * @memberof ShortCopyImage
     */
    renderLink() {
        const {externalLink, internalLink, internalSuffix, isExternal, linkName} = this.props;

        if (isExternal && externalLink && linkName) {
            return (
                <Link href={externalLink} passHref>
                    <LinkStyle rel="noopener noreferrer" target="_blank">
                        {linkName}
                    </LinkStyle>
                </Link>
            );
        }

        if (!isExternal && (internalLink || internalSuffix)) {
            if (internalLink) {
                const {name, seoSlug} = internalLink;

                return (
                    <Link href={`/${seoSlug}${internalSuffix ? `#${internalSuffix}` : ''}`} passHref>
                        <LinkStyle>
                            {linkName || name}
                        </LinkStyle>
                    </Link>
                );
            }

            return (
                <LinkStyle href={`#${internalSuffix}`}>
                    {linkName}
                </LinkStyle>
            );
        }

        return null;
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof ShortCopyImage
     */
    render() {
        const {className, copy, headline, imagePosition, isInGroup, name} = this.props;
        const link = this.renderLink();

        return (
            <ShortCopyImageElement
                className={className}
                hasLink={Boolean(link)}
                isInGroup={isInGroup}
                position={imagePosition}
            >
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                {name && <a id={parseToUri(name)} />}
                <ImageContainer position={imagePosition}>
                    {this.renderImages()}
                </ImageContainer>
                <ShortCopyImageContainer hasLink={Boolean(link)}>
                    {headline && (
                        <Headline>{headline}</Headline>
                    )}
                    <Text hasLink={Boolean(link)}>
                        {this.htmlParser.parseWithInstructions(
                            `<div data-module="ShortCopyImage">${copy}</div>`,
                            isValidNode,
                            processingInstructions
                        )}
                    </Text>
                    {link}
                </ShortCopyImageContainer>
            </ShortCopyImageElement>
        );
    }
}

export default ShortCopyImage;

const ShortCopyImageElement = styled(ModuleMargin)`
    align-items: center;
    background: ${({hasLink, theme}) => (hasLink ? theme.colors.light100 : 'transparent')};
    border: ${({hasLink, theme}) => (hasLink ? `1px solid ${theme.colors.lighterBorder}` : 'none')};
    border-radius: ${({hasLink}) => (hasLink ? clampCss(8, 'm') : 'none')};
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    overflow: ${({hasLink}) => (hasLink ? 'hidden' : 'visible')};
    position: relative;
    width: ${({hasLink}) => (hasLink ? 'calc(100% - 48px)' : '100%')};

    ${MEDIA.TABLET.UP} {
        background: transparent;
        border: none;
        border-radius: none;
        flex-direction: ${({position}) => (position === 'left' ? 'row' : 'row-reverse')};
        padding: ${({position}) => (position === 'left' ? `0 ${clampCss(57.594, 't')} 0 0` : `0 0 0 ${clampCss(57.594, 't')}`)};
        overflow: visible;
    }

    ${MEDIA.DESKTOP.UP} {
        flex-direction: ${({position}) => (position === 'left' ? 'row' : 'row-reverse')};
        margin-left: ${({position}) => (position === 'left' ? `calc(50% + max(-124px, ${pxToVw(-124, 'd', true)}))` : `calc(50% + max(124px, ${pxToVw(124, 'd')}))`)};
        margin-right: ${({position}) => (position === 'right' ? `max(-124px, ${pxToVw(-124, 'd', true)})` : '0')};
        max-width: 100%;
        padding: 0;
        transform: translateX(-50%);
        width: ${({isInGroup}) => (isInGroup ? '100%' : `calc(max(1320px, ${pxToVw(1320, 'd')}) - max(251px, ${pxToVw(251, 'd')}))`)};
    }
`;

const ImageContainer = styled.div`
    width: 100%;

    ${MEDIA.TABLET.UP} {
        align-self: flex-start;
        margin-left: ${({position}) => (position === 'right' ? '32px' : '0')};
        margin-right: ${({position}) => (position === 'left' ? '32px' : '0')};
        width: calc(60% - 16px);
    }
`;

const ShortCopyImageContainer = styled.div`
    padding: ${({hasLink}) => (hasLink ? `${clampCss(24, 'm')} ${clampCss(24, 'm')} ${clampCss(32, 'm')} ${clampCss(24, 'm')}` : `${clampCss(24, 'm')} 0 0 0`)};
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        padding: 0;
        width: calc(40% - 16px);
    }
`;

const Headline = styled.h3`
    ${FONTS.REGULAR}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(20, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(24, 'm')};
    margin: 0;
    text-align: ${({position}) => position};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(20, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(24, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(20px, ${pxToVw(20, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(24px, ${pxToVw(24, 'd')});
    }
`;

const Text = styled.div`
    ${FONTS.REGULAR}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    min-height: ${({hasLink}) => (hasLink ? clampCss(88, 'm') : 'initial')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        min-height: initial;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
    }
`;

const LinkStyle = styled.a`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    cursor: pointer;
    display: inline-block;
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    margin-top: ${clampCss(32, 'm')};
    padding: 0 ${clampCss(24, 'm')} 0 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
        background-image: url(${iconAsBackground(Arrow)});
        background-position: right;
        background-size: ${clampCss(32, 'm')} 100%;
        content: '';
        height: ${clampCss(16, 'm')};
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: width 0.2s 0s ease-in-out;
        width: ${clampCss(16, 'm')};
        will-change: width;
    }

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        padding: 0 ${clampCss(24, 't')} 0 0;

        &::after {
            background-size: ${clampCss(32, 't')} 100%;
            height: ${clampCss(16, 't')};
            width: ${clampCss(16, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
        padding: 0 max(24px, ${pxToVw(24, 'd')}) 0 0;

        &::after {
            background-size: max(32px, ${pxToVw(32, 'd')}) 100%;
            height: max(16px, ${pxToVw(16, 'd')});
            width: max(16px, ${pxToVw(16, 'd')});
        }
    }
`;