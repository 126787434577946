import React, {Component} from 'react';

import {Parser} from 'html-to-react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Carousel from 'Frontend/components/Carousel';
import {ModuleMargin} from 'Frontend/components/ModuleMargins';

import {parseToUri} from 'Admin/utils/formParsers';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';
import {isValidNode, processingInstructions} from 'Frontend/utils/parserInstructions';

/**
 * LongCopyImage
 *
 * @property {*} prop Prop comment.
 *
 * @class LongCopyImage
 * @extends {Component}
 */
class LongCopyImage extends Component {
    static propTypes = {
        copy: PropTypes.string.isRequired,
        imagePosition: PropTypes.string.isRequired,
        images: PropTypes.array.isRequired,
        className: PropTypes.string,
        headline: PropTypes.string,
        headlineBold: PropTypes.string,
        isInGroup: PropTypes.bool,
        name: PropTypes.string
    }

    static defaultProps = {
        className: '',
        headline: null,
        headlineBold: null,
        isInGroup: false,
        name: null
    }

    htmlParser = new Parser();

    /**
     * Renders the image part of the LongCopy.
     *
     * @returns {JSX} The Image jsx.
     * @memberof LongCopyImage
     */
    renderImages() {
        const {images} = this.props;

        if (images.length === 0) {
            return null;
        }

        if (images.length > 1) {
            return (
                <Carousel>
                    {images.map(img => {
                        const {alt, dimensions: {height, width}, publicPath, title} = img;

                        return (
                            <Image
                                key={publicPath.src}
                                alt={alt}
                                height={height}
                                layout="responsive"
                                src={publicPath.src}
                                title={title}
                                width={width}
                            />
                        );
                    })}
                </Carousel>
            );
        }

        const {alt, dimensions: {height, width}, publicPath, title} = images[0];

        return (
            <Image
                alt={alt}
                height={height}
                layout="responsive"
                src={publicPath.src}
                title={title}
                width={width}
            />
        );
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof LongCopyImage
     */
    render() {
        const {
            className,
            copy,
            headline,
            headlineBold,
            imagePosition,
            isInGroup,
            name
        } = this.props;

        return (
            <LongCopyImageElement className={className} isInGroup={isInGroup} position={imagePosition}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                {name && <a id={parseToUri(name)} />}
                <ImageContainer position={imagePosition}>
                    {this.renderImages()}
                </ImageContainer>
                <LongCopyImageContainer>
                    {headline && (
                        <Headline>
                            {headline}
                            {headlineBold && <HeadlineBold>{headlineBold}</HeadlineBold>}
                        </Headline>
                    )}
                    <Text>
                        {this.htmlParser.parseWithInstructions(
                            `<div data-module="LongCopyImage">${copy}</div>`,
                            isValidNode,
                            processingInstructions
                        )}
                    </Text>
                </LongCopyImageContainer>
            </LongCopyImageElement>
        );
    }
}

export default LongCopyImage;

const LongCopyImageElement = styled(ModuleMargin)`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    position: relative;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        flex-direction: ${({position}) => (position === 'left' ? 'row' : 'row-reverse')};
        padding: ${({position}) => (position === 'left' ? `0 ${clampCss(57.594, 't')} 0 0` : `0 0 0 ${clampCss(57.594, 't')}`)};
    }

    ${MEDIA.DESKTOP.UP} {
        flex-direction: ${({position}) => (position === 'left' ? 'row' : 'row-reverse')};
        margin-left: ${({position}) => (position === 'left' ? `calc(50% + max(-124px, ${pxToVw(-124, 'd', true)}))` : `calc(50% + max(124px, ${pxToVw(124, 'd')}))`)};
        margin-right: ${({position}) => (position === 'right' ? `max(-124px, ${pxToVw(-124, 'd', true)})` : '0')};
        max-width: 100%;
        padding: 0;
        transform: translateX(-50%);
        width: ${({isInGroup}) => (isInGroup ? '100%' : `calc(max(1320px, ${pxToVw(1320, 'd')}) - max(251px, ${pxToVw(251, 'd')}))`)};
    }
`;

const ImageContainer = styled.div`
    width: 100%;

    ${MEDIA.TABLET.UP} {
        align-self: flex-start;
        margin-left: ${({position}) => (position === 'right' ? '32px' : '0')};
        margin-right: ${({position}) => (position === 'left' ? '32px' : '0')};
        width: calc(60% - 16px);
    }
`;

const LongCopyImageContainer = styled.div`
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        width: calc(40% - 16px);
    }
`;

const Headline = styled.h2`
    ${FONTS.LIGHT}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(24, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(31.2, 'm')};
    margin-bottom: 0;
    margin-top: ${clampCss(18, 'm')};
    text-align: ${({position}) => position};
    text-transform: uppercase;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(24, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(31.2, 't')};
        margin-bottom: ${clampCss(18, 't')};
        margin-top: 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(32px, ${pxToVw(32, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(38.4px, ${pxToVw(38.4, 'd')});
        margin-bottom: max(18px, ${pxToVw(18, 'd')});
        margin-top: 0;
    }
`;

const HeadlineBold = styled.span`
    ${FONTS.MEDIUM}
    display: block;
    text-align: ${({position}) => position};
`;

const Text = styled.div`
    ${FONTS.REGULAR}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
    }
`;